<template>
    <div class="wrapper">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="banner设置" name="1">
                <BannerEdit/>
            </el-tab-pane>
            <el-tab-pane v-for="(item,index) in tabList" :key="item.id" :label="item.cate_name" :name="(index+2).toString()">
                <MustSee :ref="'mustsee'+Number(index+1)" :cateId="item.id"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import BannerEdit from './compontent/bannerEdit'
import MustSee from './compontent/mustSee'
import { sceneCate } from '@/api/college.js';
export default {
    name: "HomeEdit",
    data() {
        return {
            activeName: '1',
            tabList: []
        }
    },
    components: {
        BannerEdit,
        MustSee
    },
    created() {
        this.getList()
    },
    methods: {
        handleClick(val) {
            if(Number(val.index) > 0){
                this.$refs['mustsee'+val.index][0].parentList()
            }
        },
        // 获取数据
        getList() {
            sceneCate().then((res) => {
                this.tabList = res.data
            }).catch((err) => {
                console.log(err, 222)
            })
        },
    }
};
</script>