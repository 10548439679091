<template>
    <div v-loading="loading">
        <div class="top-btn">
           <el-button size="small" type="primary" @click="add()">添加</el-button>
        </div> 
        <el-table :data="list" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="title" label="链接文章"></el-table-column>
            <el-table-column prop="sort" label="排序">
                <template slot-scope="scope">
                    {{scope.$index+1}}
                </template>
            </el-table-column>
			<el-table-column prop="ad_score" label="操作">
				<template slot-scope="scope">
                    <el-link v-if="scope.$index != 0" type="primary" style="margin-right: 10px" @click="upIndex(scope)">上移</el-link>
                    <el-link v-if="scope.$index != list.length-1" type="primary" style="margin-right: 10px" @click="downIndex(scope)">下移</el-link>
                    <el-link v-if="scope.$index != 0" type="primary" style="margin-right: 10px" @click="topIndex(scope)">置顶</el-link>
                    <el-link v-if="scope.$index != list.length-1" type="primary" style="margin-right: 10px" @click="lowIndex(scope)">置底</el-link>
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteList(scope)">
                        <el-link type="primary" slot="reference">删除</el-link>
                    </el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
        <el-dialog title="选择文章" :visible.sync="dialogVisible" width="700px" :before-close="close" :close-on-click-modal='false'>
            <div class="top-btn" style="justify-content: start;">
                <el-input style="width: 200px;margin-right: 10px" size="small" placeholder="请输入文章标题搜索" v-model="artilce_title" clearable> </el-input>
                <el-button size="small" type="primary" @click="searchList()">搜索</el-button>
            </div>
            <el-table 
                :data="articleList" 
                :header-cell-style="{background:'#f5f7fa'}"
                @selection-change="handleSelectionChange" 
                style="width: 100%">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="created_at" label="发布时间">
                    <template slot-scope="scope">{{ scope.row.created_at*1000 | formatDate }}</template>
                </el-table-column>
                <el-table-column prop="title" label="文章标题"></el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status == 1 ? '上架' : '下架'}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
            <div>
                <el-button type="primary" @click="save()">确定</el-button>
                <el-button @click="close()">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getIndexSceneCateArticle, saveSceneArticle, articleList } from '@/api/college.js';
export default {
    name: "mustSee",
    props: {
		cateId: {
			type: Number,
			default: 0
        },
    },
    data() {
        return {
            loading: true,
            list: [],
            // 弹框
            dialogVisible: false,
            artilce_title: '', // 搜索
            articleList: [], // 列表
            total: 0,
            limit: 10,
            currentPage: 1,
            checkArticle: [], // 选中文章
        }
    },
    methods: {
        // 新建
        add() {
            this.dialogVisible = true
            if(this.articleList.length > 0) return
            this.getArticle()
        },
        parentList() {
            if(this.list.length > 0) return // 避免多次点击
            this.getList()
        },
        // 获取数据
        getList() {
            this.loading = true
            getIndexSceneCateArticle({
                cate_id: this.cateId
            }).then((res) => {
                this.list = res.data
                this.loading = false
            }).catch((err) => {
                this.loading = false
                console.log(err, 222)
            })
        },
        // 操作
        upIndex(scope) {
            let list = this.list.map(item=>item.id)
            list.splice(scope.$index-1,0,scope.row.id)
            list.splice(scope.$index+1,1)
            this.save(list)
        },
        downIndex(scope) {
            let list = this.list.map(item=>item.id)
            list.splice(scope.$index+2,0,scope.row.id)
            list.splice(scope.$index,1)
            this.save(list)
        },
        topIndex(scope) {
            let list = this.list.map(item=>item.id)
            list.splice(0,0,scope.row.id)
            list.splice(scope.$index+1,1)
            this.save(list)
        },
        lowIndex(scope) {
            let list = this.list.map(item=>item.id)
            list.splice(list.length,0,scope.row.id)
            list.splice(scope.$index,1)
            this.save(list)
        },
        // 删除
        deleteList(scope) {
            let list = this.list.map(item=>item.id)
            list.splice(scope.$index,1)
            this.save(list)
        },
        /**
         * 以下是弹框中内容
         */
        // 获取列表
        getArticle() {
            articleList({
                limit: this.limit,
                page: this.currentPage,
                cate_scene_id: this.cateId,
                title: this.artilce_title || null,
            }).then((res) => {
                this.total = res.data.total;
                this.articleList = res.data.data;
            }).catch((err) => {
                console.log(err, 222)
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getArticle();
        },
        // 搜索
        searchList() {
            this.currentPage = 1;
            this.getArticle()
        },
        // 多选选中
        handleSelectionChange(val) {
            this.checkArticle = val.map(item=>item.id).concat(this.list.map(item=>item.id))
        },
        // 保存
        save(list){
            if(this.checkArticle.length<=0 && list.length<=0){
                this.$message({ message: '请先选中文章', type: 'info' });
            }
            let data = {
                cate_id: this.cateId,
                article_ids: list && list.length>0 ? list : this.checkArticle
            }
			saveSceneArticle(data).then((res) => {
				if(res.code == 200) {
                    this.$message({ message: '操作成功', type: 'success' });
					this.close()
					this.getList()
				}
			}).catch((err) => {
				console.log(err, 222)
			})
        },
        // 关闭
        close() {
            this.checkArticle = []
            this.dialogVisible = false
        },
    }
};
</script>