<template>
    <div v-loading="loading">
        <div class="top-btn">
           <el-button size="small" type="primary" @click="add()">添加</el-button>
        </div> 
        <!-- 列表 -->
        <el-table :data="list" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
            <el-table-column prop="id" label="图片">
                <template slot-scope="scope">
					<img :src="scope.row.img_url || ''" style="width: 80px" alt="" />
				</template>
            </el-table-column>
			<el-table-column prop="title" label="链接文章"></el-table-column>
            <el-table-column prop="sort" label="排序"></el-table-column>
			<el-table-column prop="ad_score" label="操作">
				<template slot-scope="scope">
                    <el-link type="primary" style="margin-right: 10px" @click="edit(scope.row)">编辑</el-link>
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteList(scope.row.id)">
                        <el-link type="primary" slot="reference">删除</el-link>
                    </el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
        <!-- 弹框 -->
        <el-dialog title="添加banner" :visible.sync="dialogVisible" width="700px" :before-close="close" :close-on-click-modal='false'>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="图片">
                     <!-- :isCropper="true" :width="690" :height="306" -->
					<Qiniu :imgKey='ruleForm.img_url' @uploadSuccess='uploadSuccess'></Qiniu>
				</el-form-item>
                <el-form-item label="排序">
					<el-input-number size="small" v-model="ruleForm.sort" :min="1"></el-input-number>
				</el-form-item>
                <el-form-item label="文章">
                    {{ checkArticle }}
				</el-form-item>
                <div style="margin-left:100px">
					<div class="top-btn" style="justify-content: start;">
                        <el-input style="width: 200px;margin-right: 10px" size="small" placeholder="请输入文章标题搜索" v-model="artilce_title" clearable> </el-input>
                        <el-button size="small" type="primary" @click="searchList()">搜索</el-button>
                    </div>
                    <el-table 
                        :data="articleList" 
                        :header-cell-style="{background:'#f5f7fa'}"
                        ref="singleTable"
                        highlight-current-row
                        @current-change="handleCurrentCheck"
                        style="width: 100%">
                        <el-table-column prop="created_at" label="发布时间">
                            <template slot-scope="scope">{{ scope.row.created_at*1000 | formatDate }}</template>
                        </el-table-column>
                        <el-table-column prop="title" label="文章标题"></el-table-column>
                        <el-table-column prop="status" label="状态">
                            <template slot-scope="scope">
                                <span>{{ scope.row.status == 1 ? '上架' : '下架'}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
				</div>
                <el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
                </el-pagination>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="close()">取消</el-button>
				</el-form-item>
			</el-form>
        </el-dialog>
        <!-- 弹框结束 -->
    </div>
</template>
<script>
import { indexBanner, setIndexBanner, delBanner, articleList } from '@/api/college.js';
import Qiniu from '@/components/qiniu.vue'
export default {
    name: "mustSee",
    data() {
        return {
            loading: true,
            list: [],
            editId: '', //选中编辑id
            // 弹框
            dialogVisible: false,
            artilce_title: '', // 搜索
            articleList: [], // 列表
            total: 0,
            limit: 10,
            currentPage: 1,
            checkArticle: '', // 选中文章
            ruleForm: {
                img_url: '',
                article_id: '',
                sort: 1
            },
            rules: {
                img_url: [
                    { required: true, message: '请上传图片', trigger: 'change' }
                ],
                article_id: [
                    { required: true, message: '请选择文章', trigger: 'blur' }
                ],
            }
        }
    },
    components: {
        Qiniu
    },
    mounted() {
        this.getList()
    },
    methods: {
        // 新建
        add() {
            this.editId = null
            this.dialogVisible = true
            if(this.articleList.length > 0) {
                this.setArticle()
            }else {
                this.getArticle()
            }
        },
        // 编辑
        edit(row) {
            this.editId = row.id
            this.dialogVisible = true
            // 赋值
            this.ruleForm.img_url = row.img_url
            this.ruleForm.article_id = row.article_id
            this.ruleForm.sort = row.sort
            this.checkArticle = row.title
            if(this.articleList.length > 0) {
                this.setArticle()
            }else {
                this.getArticle()
            }
        },
        // 关闭弹框
        close() {
            this.dialogVisible = false
            this.ruleForm = {
                img_url: '',
                article_id: '',
                sort: 1
            }
            this.checkArticle = ''
        },
        // 获取数据
        getList() {
            this.loading = true
            indexBanner().then((res) => {
                console.log(res, 1111)
                this.list = res.data
                this.loading = false
            }).catch((err) => {
                this.loading = false
                console.log(err, 222)
            })
        },
        // 删除
        deleteList(id) {
            delBanner({ id}).then((res) => {
                if(res.code == 200) {
                    this.$message({ message: '删除成功', type: 'success' });
                    this.getList()
                }
            }).catch((err) => {
                console.log(err, 222)
            })
        },
        /**
         * 以下是弹框中内容
         */
        // 获取弹框内容
        getArticle() {
            articleList({
                limit: this.limit,
                page: this.currentPage,
                title: this.artilce_title || null,
            }).then((res) => {
                this.total = res.data.total;
                this.articleList = res.data.data;
                this.setArticle()
            }).catch((err) => {
                console.log(err, 222)
            })
        },
        // 设置选中文章回显
        setArticle() {
            let index = this.articleList.findIndex(item=>item.id==this.ruleForm.article_id)
            let row = index < 0 ? '' : this.articleList[index]
            this.$refs.singleTable.setCurrentRow(row);
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        // 搜索
        searchList() {
            this.currentPage = 1;
            this.getArticle()
        },
        // 选中一行
        handleCurrentCheck(val) {
            this.checkArticle = val.title
            this.ruleForm.article_id = val.id
        },
        // 创建
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) { 
                    this.save() 
                }
            });
        },
        save() {
            let body = this.ruleForm
			this.editId ? body.id = this.editId : ''
			setIndexBanner(body).then((res) => {
				if(res.code == 200){
					this.close()
					this.getList()
				}
			}).catch((err) => {
				console.log(err, 222)
			})
        },
        // 上传图片成功
        uploadSuccess(res) {
            this.ruleForm.img_url = this.$constant.qnUrl + '/' + res.key
        },
    }
};
</script>